<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">OPEN COOP SAVINGS PROTECT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_of_effective"
                label="Date of Effective"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                v-model="series_no_id"
                dense
                outlined
                label="Series #"
                item-text="series_no"
                item-value="id"
                :items="series_no_items"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-select
                v-model="pricing_id"
                dense
                outlined
                label="Product"
                item-text="product_name"
                item-value="id"
                :items="pricing_items"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-combobox
                      class="mx-2"
                      :append-icon="icons.mdiAccountSearchOutline"
                      v-model="search_co"
                      @keyup.enter="searching_search_items_co($event.target.value)"
                      @change="get_search_items_info_co"
                      :items="search_items_co"
                      item-value="id"
                      item-text="name"
                      placeholder="Search by (Lastname/Firstname/CMF #)"
                      ref="memberCombobox2"
                      dense
                      outlined
                    ></v-combobox>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="permanent_address"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="zip_code"
                      dense
                      outlined
                      label="Zip Code"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no"
                      dense
                      outlined
                      type="number"
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="occupation"
                      dense
                      label="Occupation"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-card
                      flat
                    >
                      <v-toolbar dense dark color="primary">
                        <v-toolbar-title><h4 class="font-weight-light">REPRESENTATIVES</h4>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-row class="mt-3">
                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-card-actions class="px-2">
                              Add More Relatives
                              <v-btn class="mx-2" fab dark small color="success"
                                     @click="add_representatives_branch">
                                <v-icon dark>{{ icons.mdiPlus }}</v-icon>
                              </v-btn>
                              <v-divider class="mx-2"/>
                            </v-card-actions>
                          </v-col>
                          <v-col
                            md="12"
                            cols="12"
                          >
                            <v-col v-for="(item, index) in representatives_items" :key="index">
                              <v-card-actions>
                                <v-spacer/>
                                Remove Representatives Branch
                                <v-btn class="mx-2" fab dark small color="error"
                                       @click="remove_representatives_branch(index)"
                                       v-if="item.id===0">
                                  <v-icon dark>{{ icons.mdiMinus }}</v-icon>
                                </v-btn>
                              </v-card-actions>
                              <v-row class="align-center mb-2">
                                <v-col md="8"
                                       cols="12">
                                  <v-text-field
                                    v-model="item.name"
                                    label="Name"
                                    class="mx-2"
                                    :rules="rules.default_max_255_character_and_no_empty_rule"
                                    required
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col md="4"
                                       cols="12">
                                  <v-text-field
                                    v-model="item.relationship"
                                    label="Relationship"
                                    class="mx-2"
                                    :rules="rules.default_max_100_character_and_no_empty_rule"
                                    required
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-divider class="mx-2"/>
                            </v-col>
                          </v-col>

                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                v-model="sales_staff_id"
                dense
                outlined
                label="Sales Agent"
                item-text="full_name"
                item-value="id"
                :items="sales_staff_items"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                v-model="collector_staff_id"
                dense
                outlined
                label="Collector"
                item-text="full_name"
                item-value="id"
                :items="collector_staff_items"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <!-- alert -->
            <v-col cols="12" v-if="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {
  mdiAlertOutline,
  mdiCloudUploadOutline,
  mdiPlus,
  mdiMinus,
  mdiAccountSearchOutline
} from '@mdi/js'
import {mapGetters, mapActions} from "vuex";
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    saving: false,
    alert: false,
    alert_message: '',

    search_co: '',
    search_member_co: '',
    search_items_co: [],

    series_no_id: '',
    series_no_items: [],

    pricing_id: '',
    pricing_items: [],

    representatives_items: [],

    sales_staff_id: '',
    sales_staff_items: [],
    collector_staff_id: '',
    collector_staff_items: [],

    date_of_effective: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    zip_code: '',
    date_of_birth: '',
    age: 0,
    religion: '',
    contact_no: '',
    occupation: '',
    civil_status: '',
    gender: '',
    permanent_address: '',
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPlus,
        mdiMinus,
        mdiAccountSearchOutline,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.list_of_coop_savings_protect_pricing({
      is_active: 1,
    })
      .then(response => {
        this.pricing_items = response.data
      })
      .catch(error => {
        console.log(error)
      })
    this.list_of_available_series_coop_savings_protect({
      branch_id: this.branch_id,
    })
      .then(response => {
        this.series_no_items = response.data
      })
      .catch(error => {
        console.log(error)
      })
    this.get_CoopSavingsProtectStaff_by_position({
      positions: 'Sales',
    })
      .then(response => {
        this.sales_staff_items = response.data
      })
      .catch(error => {
        console.log(error)
      })
    this.get_CoopSavingsProtectStaff_by_position({
      positions: 'Collector',
    })
      .then(response => {
        this.collector_staff_items = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('coop_savings_protect_pricing', ['list_of_coop_savings_protect_pricing']),
    ...mapActions('series_coop_savings_protect', ['list_of_available_series_coop_savings_protect']),
    ...mapActions('coop_savings_protect_info', ['create_coop_savings_protect_info']),
    ...mapActions('members_information', ['search_members_paid',]),
    ...mapActions('coop_savings_protect_staff', ['get_CoopSavingsProtectStaff_by_position',]),
    reset() {
      Object.assign(this.$data, initialState())
    },
    add_representatives_branch() {
      if (this.representatives_items.length < 3) {
        this.representatives_items.push(
          {
            id: 0,
            funeral_category: '',
            address: '',
            name: '',
            contact_no: '',
          }
        )
      }
    },
    remove_representatives_branch(index) {
      this.representatives_items.splice(index, index + 1)
    },
    searching_co(value) {
      this.search_member_co = value
    },
    searching_search_items_co(value) {
      this.search_members_paid({
        branch_id: this.branch_id,
        search_word: value
      })
        .then(response => {
          this.search_items_co = response.data
        })
    },
    resett2() {
      this.search_co = ''
      this.search_member_co = ''
      this.search_items_co = []
    },
    get_search_items_info_co() {
      if (this.search_items_co.length > 0) {
        var index = this.search_items_co.map(function (x) {
          return x.id;
        }).indexOf(this.search_co.id)
        if (index != -1) {
          this.last_name = this.search_items_co[index].member.last_name
          this.first_name = this.search_items_co[index].member.first_name
          this.middle_name = this.search_items_co[index].member.middle_name
          this.permanent_address = this.search_items_co[index].member.permanent_address
        } else {
          this.resett2()
        }
      } else {
        this.resett2()
      }
    },
    save_loan_application() {
      this.saving = true
      this.alert = false

      var proceed = true;
      var full_name = this.last_name.toUpperCase() + ', ' + this.first_name.toUpperCase() + ' ' + this.middle_name.toUpperCase();
      if (proceed) {
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('date_of_effective', this.date_of_effective);

          data.append('series_no_id', this.series_no_id);

          data.append('member_id', this.search_co.id);

          data.append('pricing_id', this.pricing_id);
          data.append('permanent_address', this.permanent_address.toUpperCase());
          data.append('zip_code', this.zip_code);
          data.append('date_of_birth', this.date_of_birth);
          data.append('religion', this.religion);
          data.append('contact_no', this.contact_no);
          data.append('occupation', this.occupation);
          data.append('civil_status', this.civil_status);
          data.append('gender', this.gender);
          data.append('sales_staff_id', this.sales_staff_id);
          data.append('collector_staff_id', this.collector_staff_id);

          data.append('representatives', JSON.stringify(this.representatives_items));
          this.create_coop_savings_protect_info(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              console.log(error)
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
}
</script>
