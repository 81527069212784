import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import branch from './module/data/branch'
import transaction_months from './module/data/transaction_months'
import members_information from './module/data/members_information'
import bank_depositories from './module/data/bank_depositories'
import or_series from './module/data/or_series'
import staff from './module/data/staff'
import deposit_slips from './module/data/deposit_slips'
import payments from './module/data/payments'
import members_payment from './module/data/members_payment'
import loans_data from './module/data/loans_data'
import loans_payment from './module/data/loans_payment'
import processing_payment from './module/data/processing_payment'
import share_capital from './module/data/share_capital'
import loans_insurance_payment from './module/data/loans_insurance_payment'
import mortuary_insurance_payment from './module/data/mortuary_insurance_payment'
import foundation_payment from './module/data/foundation_payment'
import gadget_loans_data from './module/data/gadget_loans_data'
import gadget_loans_payment from './module/data/gadget_loans_payment'
import gadget_processing_payment from './module/data/gadget_processing_payment'
import regular_savings_info from './module/data/regular_savings_info'
import time_deposits from './module/data/time_deposits'
import regular_savings_data from './module/data/regular_savings_data'
import teller_fund from './module/data/teller_fund'
import monthly_collection_reports from './module/data/monthly_collection_reports'
import db_file from './module/data/db_file'
import loans_data_assumed from './module/data/loans_data_assumed'
import gadget_loans_data_assumed from './module/data/gadget_loans_data_assumed'
import income_summary from './module/data/income_summary'
import expenses_summary from './module/data/expenses_summary'
import payables_summary from './module/data/payables_summary'
import raw_lots_location_data from './module/data/raw_lots_location_data'
import raw_lots_payment from './module/data/raw_lots_payment'
import raw_lots_rent from './module/data/raw_lots_rent'
import summary_report from './module/data/summary_report'
import dashboard from './module/data/dashboard'
import transmital_or from './module/data/transmital_or'
import cais_reports from './module/data/cais_reports'
import start_new_month from './module/data/start_new_month'
import financial_statement from './module/data/financial_statement'
import monthly_quota_reports from './module/data/monthly_quota_reports'
import transmital_passbooks from './module/data/transmital_passbooks'
import yearly_dividend from './module/data/yearly_dividend'
import organization_group from './module/data/organization_group'
import organization_position from './module/data/organization_position'
import organization_history from './module/data/organization_history'
import inventory from './module/data/inventory'
import coop_protek_information from './module/data/coop_protek_information'
import coop_protek_savings_data from './module/data/coop_protek_savings_data'
import coop_savings_protect_pricing from './module/data/coop_savings_protect_pricing'
import series_coop_savings_protect from './module/data/series_coop_savings_protect'
import coop_savings_protect_info from './module/data/coop_savings_protect_info'
import coop_savings_protect_staff from './module/data/coop_savings_protect_staff'
import coop_savings_protect_data from './module/data/coop_savings_protect_data'


const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'GGC-COOP',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    branch,
    transaction_months,
    members_information,
    bank_depositories,
    or_series,
    staff,
    deposit_slips,
    members_payment,
    payments,
    loans_data,
    loans_payment,
    processing_payment,
    share_capital,
    loans_insurance_payment,
    mortuary_insurance_payment,
    foundation_payment,
    gadget_loans_data,
    gadget_loans_payment,
    gadget_processing_payment,
    regular_savings_info,
    time_deposits,
    regular_savings_data,
    teller_fund,
    monthly_collection_reports,
    db_file,
    loans_data_assumed,
    gadget_loans_data_assumed,
    income_summary,
    expenses_summary,
    payables_summary,
    raw_lots_location_data,
    raw_lots_payment,
    raw_lots_rent,
    summary_report,
    dashboard,
    transmital_or,
    cais_reports,
    start_new_month,
    financial_statement,
    monthly_quota_reports,
    transmital_passbooks,
    yearly_dividend,
    organization_group,
    organization_position,
    organization_history,
    inventory,
    coop_protek_information,
    coop_protek_savings_data,
    coop_savings_protect_pricing,
    series_coop_savings_protect,
    coop_savings_protect_info,
    coop_savings_protect_staff,
    coop_savings_protect_data,
  }
})
