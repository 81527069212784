<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REGULAR SAVINGS PRINTING PASSBOOK</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
      </v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Account #
            </th>
            <th class="text-center text-uppercase">
              Account Name
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase">
              Print Information
            </th>
            <th class="text-center text-uppercase">
              Print Passbook Data
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in search_items"
            :key="item.id"
          >
            <td>{{ item.account_no }}</td>
            <td class="text-center">
              {{ item.account_name }}
            </td>
            <td class="text-center">
              ???,???.??
            </td>
            <td class="text-center">
              {{ item.status }}
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="selected_passbook_print(item)"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                @click="print_passbook_datas(item)"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="dialog_value" width="80%">
      <DepositSlipsPassbook :key="key"
                            v-on:data="on_save_dep"
                            :data="details"
      ></DepositSlipsPassbook>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import DepositSlipsPassbook from './deposits_interface/DepositSlipsPassbook'

  export default {
    components: {
      snackBarDialog,
      DepositSlipsPassbook,
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        dialog_value: false,

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],
        balance: 0,
        balance_num: 0,

        account_id: '',
        account_no: '',
        account_name: '',
        amount: 0,

        status: '',
        key: 0,
        details: {},
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'user_id', 'month_start', 'month_end', 'month_of', 'branch']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['search_staff_by_category']),
      ...mapActions('regular_savings_info', ['search_members_have_savings_account']),
      ...mapActions('regular_savings_data', ['print_passbook_data']),
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_members_have_savings_account({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      on_save_dep(value) {
        this.dialog_value = false
        this.print_passbook_details(this.details, value)
      },
      selected_passbook_print(data) {
        this.key++
        this.dialog_value = true
        this.account_id = data.id
        this.details = data
      },
      print_passbook_details(data, data2) {
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: data.account_name,
              style: 'printed_label'
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            }, {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              columns: [
                {text: this.branch, style: 'printed_label2'},
                {text: 'PESO', style: 'printed_label2'},
                {text: data.account_no, style: 'printed_label2'},
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
              ],
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              text: '.',
              style: {fontSize: 2},
            },
            {
              columns: [
                {
                  text: moment().format('MMMM DD, YYYY') ,
                  style: 'printed_label2'
                },
                {
                  text:  moment().format('hh:mm:ss'),
                  style: 'printed_label2'
                },
                {text: 'SEQ. #' + data2, style: 'printed_label2'},
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
                {
                  text: '.',
                  style: {fontSize: 2},
                },
              ],
            },
          ],
          styles: {
            printed_label: {
              fontSize: 11,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            printed_label2: {
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
          }
        }
        pdfMake.createPdf(docDefinition).open();
      },
      print_passbook_datas(data) {
        this.print_passbook_data({
          account_name: data.account_name,
          account_no: data.account_no
        })
          .then(response => {
            var payments_array = []
            if (response.data.length > 0) {
              response.data.forEach(function (item) {
                payments_array.push(
                  [
                    {text: item.date, alignment: 'left', style: {fontSize: 8}},
                    {text: item.withdrawal, alignment: 'center', style: {fontSize: 9}},
                    {text: item.deposit, alignment: 'center', style: {fontSize: 9}},
                    {text: item.balance, alignment: 'center', style: {fontSize: 9}},
                    {text: item.code, alignment: 'left', style: {fontSize: 9}},
                  ]
                )
              })
            } else {
              payments_array.push(
                [
                  '----',
                  '----',
                  '----',
                  '----',
                  '----',
                ]
              )
            }
            //call pdfmake
            var pdfMake = require('pdfmake/build/pdfmake.js')
            if (pdfMake.vfs == undefined) {
              var pdfFonts = require('pdfmake/build/vfs_fonts.js')
              pdfMake.vfs = pdfFonts.pdfMake.vfs;
            }
            var docDefinition = {
              pageSize: 'LETTER',
              content: [
                {
                  style: 'tableExample',
                  color: '#444',
                  table: {
                    widths: [50, 50, 65, 65, 60],
                    body: payments_array,
                  },
                  layout: {
                    hLineWidth: function (i) {
                      return i === 0 ? 0 : 0;
                    },
                    vLineWidth: function (i) {
                      return (i === 0) ? 0 : 0;
                    },
                  },
                },
              ],
              styles: {
                tableExample: {
                  fontSize: 8,
                  bold: true,
                },
              },
            }
            pdfMake.createPdf(docDefinition).open();
          })
      },
    }
  }
</script>
