<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ORGANIZATION GROUP DETAILS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>

            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="organization_name"
                label="Name of Organization"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_staff_details"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12">
            <v-data-table dense
                          :headers="headers"
                          :items="items"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-icon
                      class="mr-2"
                      color="success"
                      @click="add_dialog(item)"
                    >
                      {{icons.mdiPlus}}
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="view_dialog" persistent max-width="80%" :key="this.key2">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">ADD MEMBERS ON ORGANIZATION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-col
          md="12"
          cols="12"
        >
          <v-combobox
            class="mx-2"
            :append-icon="icons.mdiAccountSearchOutline"
            v-model="search"
            @keyup="searching_search_items($event.target.value)"
            @change="get_search_items_info"
            :items="search_items"
            item-value="id"
            item-text="name"
            placeholder="Search by (Lastname/Firstname/CMF #)"
            ref="memberCombobox"
            dense
            outlined
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-data-table dense
                        :headers="headers2"
                        :items="items2"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.last_name+', '+item.first_name+' '+item.middle_name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-btn color="error" @click="view_dialog = false"> Close</v-btn>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline, mdiCloudUploadOutline
    , mdiPlus
    , mdiAccountSearchOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPlus,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',
        view_dialog: false,
        organization_name: '',
        key2: 0,
        headers: [
          {text: 'Organization Name', value: 'name', sortable: false},
          {text: 'Add Member', value: 'sequence', sortable: false},
        ],
        headers2: [
          {text: 'Name', value: 'name', sortable: false},
        ],
        items: [],
        items2: [],
        search_items: [],
        search: '',
        org_id: '',
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('organization_group', ['register_organization_group', 'list_of_organization', 'update_member_organization']),
      ...mapActions('members_information', ['search_members_paid']),
      initialize_data() {
        this.list_of_organization({
          branch_id: this.branch_id
        })
          .then(response => {
            this.items = response.data
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      searching_search_items(value) {
        this.search_members_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      async get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            await this.update_member_organization({
              member_id: this.search_items[index].id,
              org_id: this.org_id,
            })
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.search = ''
              })
            await this.initialize_data()
          }
        }
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.organization_name = ''
        this.initialize_data()
      },
      add_dialog(value) {
        this.org_id = value.id
        this.items2 = value.members
        this.view_dialog = true
        this.key2++
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('name', this.organization_name.toUpperCase());
          this.register_organization_group(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
