<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF MBAI FEE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2 pt-5">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              @change="get_list_of_processing_fee_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
                        <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_loan_approved_data()"
                                                >
                                                {{ icons.mdiPrinter }}
                                                </v-icon>
                                            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        :height="height"
        :headers="headers"
        :items="list_of_processing_fee"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!--            <span class="headline">Paid: {{paid_members}}</span>-->
            <!--            <v-divider-->
            <!--              class="mx-4"-->
            <!--              inset-->
            <!--              vertical-->
            <!--            ></v-divider>-->
            <!--            <span class="headline">Unpaid: {{unpaid_members}}</span>-->
            <!--            <v-divider-->
            <!--              class="mx-4"-->
            <!--              inset-->
            <!--              vertical-->
            <!--            ></v-divider>-->
            <!--            <span class="headline">TOTAL: {{paid_members+unpaid_members}}</span>-->

            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:item.is_pending_nmapi="{ item }">
          <div v-if="false">
            <v-chip color="success" dark>PAID</v-chip>
          </div>
          <div v-else>
            <v-chip color="error" dark>UNPAID</v-chip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from "moment";
import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountSearchOutline,
        mdiPrinter,
      },
    }
  },
  data() {
    return {
      month_of: '',
      category: '',
      list_of_processing_fee: [],
      month_of_items: [],
      unpaid_members: 0,
      paid_members: 0,
      can_print: false,
      //table props
      search: '',
      data_table_loading: false,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Loan Application No', value: 'laf_no'},
        {text: 'Last Name', value: 'members.last_name'},
        {text: 'First Name', value: 'members.first_name'},
        {text: 'Amount', value: 'mbai'},
        {text: 'Status', value: 'is_pending_nmapi'},
      ],
      height: 430
    }
  },
  mounted() {
    const data = new FormData()
    data.append('branch_id', this.branch_id);
    this.retrieve_transaction_month(data)
      .then(response => {
        this.month_of_items = response.data
        this.month_of_items.splice(0, 0, {
          id: -1,
          month_of: 'All'
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['company_logo']),
    ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no']),
  },
  watch: {
    branch_id: function () {
      this.can_print = false
      this.month_of = ''
      this.list_of_processing_fee = []
      this.unpaid_members = '0'
      this.paid_members = '0'
    }
  },
  methods: {
    ...mapActions('transaction_months', ['retrieve_transaction_month']),
    ...mapActions('loans_data', ['list_of_mbai_fee']),
    get_list_of_processing_fee_items() {
      if (this.month_of_items.length > 0) {
        var index = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        if (index != -1) {
          this.data_table_loading = true
          const data = new FormData()
          data.append('month_of', this.month_of_items[index].month_of);
          data.append('branch_id', this.branch_id);
          this.list_of_mbai_fee(data)
            .then((response) => {
              this.can_print = true
              this.list_of_processing_fee = response.data
              // this.unpaid_members = response.data[0].unpaid
              // this.paid_members = response.data[0].paid
              this.data_table_loading = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    print_loan_approved_data() {
      var imgData = this.company_logo
      var array = []
      var indexMonth = this.month_of_items.map(function (x) {
        return x.id
      }).indexOf(this.month_of)

      var month = this.month_of_items[indexMonth].month_of
      array.push(
        [
          {text: 'ID', alignment: 'center', style: 'label'},
          {text: 'Loan Application No', alignment: 'center', style: 'label'},
          {text: 'Name', alignment: 'center', style: 'label'},
          {text: 'Amount', alignment: 'center', style: 'label'},
        ]
      )
      if (this.list_of_processing_fee.length > 0) {
        this.list_of_processing_fee.forEach(function (item) {
          var status = 'Unpaid'
          if (false) {
            status = 'Paid'
          }
          array.push(
            [
              {text: item.id, alignment: 'center'},
              {text: item.laf_no, alignment: 'left'},
              {text: item.members.last_name + ', ' + item.members.first_name, alignment: 'left'},
              {text: item.mbai, alignment: 'left'},
            ]
          )
        })
      } else {
        array.push(
          [
            '----',
            '----',
            '----',
            '----',
          ]
        )
      }
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        pageSize: 'LETTER',
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                  {text: this.branch_address, style: 'subheader'},
                  {
                    text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader'
                  },
                ],
                style: 'header'
              },
            ]
          },
          {text: 'LIST OF NMAPI FEE', style: 'title'},
          '================================================================================',
          {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
          {text: 'Branch: ' + this.branch, style: 'main_info'},
          {text: 'Month of: ' + month, style: 'main_info'},
          ' ',
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: [30, 90, 230, 90, 50],
              body: array,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex === 0) ? '#CCCCCC' : null;
              },
            },
          },
          {
            stack: [
              {
                columns: [
                  {text: 'Prepared By: ', style: 'aa_top_margin'},
                  {text: 'Received By:', style: 'cashier_top_margin'},
                  {text: ''},
                ]
              },
              {
                columns: [
                  {text: '', style: 'aa_for'},
                  {text: '', style: 'cashier_for'},
                  {text: ''},
                ]
              },
              {
                columns: [
                  {text: '_________________________________', style: 'aa_line'},
                  {text: '_________________________________', style: 'cashier_line'},
                  {text: ''},
                ]
              },
              {
                columns: [
                  {text: 'Collector', style: 'aa_placeholder'},
                  {text: 'Cashier', style: 'cashier_placeholder'},
                  {text: ''},
                ]
              },
            ]
          },
          {
            stack: [
              {
                columns: [
                  {text: 'Verified By: ', style: 'bm_top_margin'},
                  {text: 'Audited By: ', style: 'au_top_margin'},
                ]
              },
              {
                columns: [
                  {text: '', style: 'cs_for'},
                  {text: '', style: 'bm_for'},
                  {text: '', style: 'au_for'},
                ]
              },
              {
                columns: [
                  {text: '_________________________________', style: 'bm_line'},
                  {text: '_________________________________', style: 'au_line'},
                ]
              },
              {
                columns: [
                  {text: 'Collection Supervisor', style: 'cs_placeholder'},
                  {text: 'Branch Manager', style: 'bm_placeholder'},
                  {text: 'Asst. Auditor', style: 'au_placeholder'},
                ]
              },
            ]
          },
        ],
        footer: {
          columns: [
            {
              text: 'Generated with Goodlife Savings & Credit Cooperative System',
              alignment: 'center',
              style: 'tableExample'
            }
          ]
        },
        styles: {
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 20]//[left, top, right, bottom]
          },
          subheader: {
            fontSize: 12
          },
          title: {
            fontSize: 15,
            alignment: 'center',
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0]//[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 4, 0, 0]//[left, top, right, bottom]
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
          },
          sub_info: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
          },
          tableExample: {
            fontSize: 7,
          },

          aa_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          bm_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          cs_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          aa_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          aa_placeholder: {
            margin: [15, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          aa_for: {
            margin: [0, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_for: {
            margin: [2, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_for: {
            margin: [3, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_for: {
            margin: [3, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_placeholder: {
            margin: [26, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_placeholder: {
            margin: [2, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_placeholder: {
            margin: [5, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          au_placeholder: {
            margin: [2, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_for: {
            margin: [2, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
        },
      }
      pdfMake.createPdf(docDefinition).open();
    },
  }
}
</script>
