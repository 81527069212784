<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">COOP SAVINGS PROTECT STAFF's DETAILS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="last_name"
                label="Last Name"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="first_name"
                label="First Name"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="middle_name"
                label="Middle Name"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="positions"
                dense
                outlined
                label="Positions"
                :items="['Sales','Collector']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{ alert_message }}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
import {mapGetters, mapActions} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  data() {
    return {

      saving: false,
      alert: false,
      alert_message: '',

      last_name: '',
      first_name: '',
      middle_name: '',
      positions: '',
    }
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    ...mapGetters('authentication', ['branch_id']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('coop_savings_protect_staff', ['create_CoopSavingsProtectStaff', 'is_staff_already_encoded_CoopSavingsProtectStaff']),
    reset() {
      this.saving = false
      this.alert = false
      this.alert_message = ''

      this.last_name = ''
      this.first_name = ''
      this.middle_name = ''
      this.positions = ''
    },
    save_staff_details() {
      this.saving = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('last_name', this.last_name.toUpperCase());
        data.append('first_name', this.first_name.toUpperCase());
        data.append('middle_name', this.middle_name.toUpperCase());
        data.append('positions', this.positions);
        this.is_staff_already_encoded_CoopSavingsProtectStaff(data)
          .then(response => {
            if (response.data == 1) {
              this.alert = true
              this.alert_message = 'Staff Already Encoded!'
              this.saving = false
            } else {
              this.create_CoopSavingsProtectStaff(data)
                .then(response => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: response.data,
                  })
                  this.reset()
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
            }
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    }
  }
}
</script>
