<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="accent">
        <v-toolbar-title><h4 class="font-weight-light">PASSBOOK FEE DEPOSIT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                <v-text-field
                  v-model="data.account_no"
                  label="Account No"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="data.account_name"
                  label="Account Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="data.status"
                  label="Status"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-col></v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="amount"
                    label="Amount Passbook Fee"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
                class="d-none d-sm-flex justify-center position-relative"
              >
                <v-img
                  contain
                  max-width="170"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_deposit_slips"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      data: Object,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        amount: 30,
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'user_id', 'branch', 'month_start', 'month_end', 'month_of']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('regular_savings_info', ['search_members_have_savings_account']),
      ...mapActions('transmital_passbooks', ['passbook_fee_deposit']),
      save_deposit_slips() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('regular_savings_info_id', this.data.id);
          data.append('amount', this.amount);
          this.passbook_fee_deposit(data)
            .then(response => {
              if (response.status === 201) {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: response.data,
                })
              } else {
                this.print_deposit_slip(response.data.id, moment().format('MMMM DD, YYYY'), moment().format('hh:mm:ss'), this.data.id)
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: 'SUCCESSFULLY DEPOSITED PASSBOOK FEE!!!',
                })
                this.$emit('data',response.data.serial_no)
              }
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      print_deposit_slip(id, dateeee, time, reg_id) {
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {text: 'Passbook Fee, Cash Deposit', style: 'printed_label'},
            {text: 'REFPASBK' + id, style: 'printed_label'},
            {text: this.account_no, style: 'printed_label'},
            {text: this.account_name, style: 'printed_label'},
            {text: 'PHP ' + this.amount, style: 'printed_label'},
            {text: 'PASSBOOK FEE', style: 'printed_label'},
            {text: dateeee + ' ' + time, style: 'printed_label'},
            {text: 'RGST' + reg_id, style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'printed_label'},
          ],
          styles: {
            printed_label: {
              color: 'red',
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
          }
        }
        pdfMake.createPdf(docDefinition).open();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .two-factor-auth {
    max-width: 25rem;
  }

  .security-character {
    position: absolute;
    bottom: -0.5rem;
  }
</style>
